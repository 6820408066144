.app-rolling-text {
  /**
    * As defined in the directive
    */
  --container-width: var(--app-rolling-text-container-width);

  /**
    * Provided as an input to the directive
    */
  --transition-time: var(--app-rolling-text-container-transition-time);
}

/**
 * Implement for a target-able element "X" by adding ".app-rolling-text" and within providing
 * appropriate styles for ".app-rolling-text-expanded" and ".app-rolling-text-translated".
 * The former of the two classes is used to make the content expand to its full width, while
 * the latter is used to translate the content to the left if it is wider than its parent.
 */

// mat-list-item
mat-list-item.app-rolling-text {
  // ".mdc-list-item__content" is the container element

  .mdc-list-item__content > * {
    // prevent line breaks
    white-space: nowrap;

    transform: translateX(0);
    transition: transform var(--transition-time) ease;
  }

  &.app-rolling-text-expanded {
    .mdc-list-item__content > * {
      // makes this element as wide as its content, possibly wider than its parent
      width: fit-content;
    }
  }

  &.app-rolling-text-translated {
    .mdc-list-item__content > * {
      // translate to the left only if the content is wider than the parent
      transform: translateX(min(0%, var(--container-width) - 100%));
    }
  }
}

app-selection-list .mock-list .item.app-rolling-text {
  .title, .line {
    transform: translateX(0);
    transition: transform var(--transition-time) ease;
  }

  &.app-rolling-text-expanded {
    .title, .line {
      // makes this element as wide as its content, possibly wider than its parent
      width: fit-content;
    }
  }

  &.app-rolling-text-translated {
    .title, .line {
      // translate to the left only if the content is wider than the parent
      transform: translateX(min(0%, var(--container-width) - 100%));
    }
  }
}