// exposes css typography variables as sass variables
$app-font-display-large:   var(--mat-sys-display-large);
$app-font-display-medium:  var(--mat-sys-display-medium);
$app-font-display-small:   var(--mat-sys-display-small);
$app-font-headline-large:  var(--mat-sys-headline-large);
$app-font-headline-medium: var(--mat-sys-headline-medium);
$app-font-headline-small:  var(--mat-sys-headline-small);
$app-font-title-large:     var(--mat-sys-title-large);
$app-font-title-medium:    var(--mat-sys-title-medium);
$app-font-title-small:     var(--mat-sys-title-small);
$app-font-body-large:      var(--mat-sys-body-large);
$app-font-body-medium:     var(--mat-sys-body-medium);
$app-font-body-small:      var(--mat-sys-body-small);
$app-font-label-large:     var(--mat-sys-label-large);
$app-font-label-medium:    var(--mat-sys-label-medium);
$app-font-label-small:     var(--mat-sys-label-small);

$app-font-size-display-large:   var(--mat-sys-display-large-size);
$app-font-size-display-medium:  var(--mat-sys-display-medium-size);
$app-font-size-display-small:   var(--mat-sys-display-small-size);
$app-font-size-headline-large:  var(--mat-sys-headline-large-size);
$app-font-size-headline-medium: var(--mat-sys-headline-medium-size);
$app-font-size-headline-small:  var(--mat-sys-headline-small-size);
$app-font-size-title-large:     var(--mat-sys-title-large-size);
$app-font-size-title-medium:    var(--mat-sys-title-medium-size);
$app-font-size-title-small:     var(--mat-sys-title-small-size);
$app-font-size-body-large:      var(--mat-sys-body-large-size);
$app-font-size-body-medium:     var(--mat-sys-body-medium-size);
$app-font-size-body-small:      var(--mat-sys-body-small-size);
$app-font-size-label-large:     var(--mat-sys-label-large-size);
$app-font-size-label-medium:    var(--mat-sys-label-medium-size);
$app-font-size-label-small:     var(--mat-sys-label-small-size);