@use 'abstract/mixins' as mix;

mat-list-item {
  &.dynamic-height {
    min-height: 56px;
    height: unset !important;

    .mdc-list-item__start {
      align-self: center !important;
      margin-top: 0 !important;
    }

    .mdc-list-item__secondary-text {
      padding-bottom: 15px;
      span {
        white-space: normal;
      }
    }

    .mdc-list-item__end {
      &.mat-icon {
        @include mix.material-symbols-outlined-font;
        align-self: center !important;

        &::before {
          display: none
        }
      }

      &:not(.mat-icon) {
        align-self: center !important;

        &::before {
          display: none
        }
      }
    }
  }
}