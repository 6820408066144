@use 'abstract/variables' as var;

hr {
  border: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}

.app-display-large   { font: var.$app-font-display-large;   }
.app-display-medium  { font: var.$app-font-display-medium;  }
.app-display-small   { font: var.$app-font-display-small;   }
.app-headline-large  { font: var.$app-font-headline-large;  }
.app-headline-medium { font: var.$app-font-headline-medium; }
.app-headline-small  { font: var.$app-font-headline-small;  }
.app-title-large     { font: var.$app-font-title-large;     }
.app-title-medium    { font: var.$app-font-title-medium;    }
.app-title-small     { font: var.$app-font-title-small;     }
.app-body-large      { font: var.$app-font-body-large;      }
.app-body-medium     { font: var.$app-font-body-medium;     }
.app-body-small      { font: var.$app-font-body-small;      }
.app-label-large     { font: var.$app-font-label-large;     }
.app-label-medium    { font: var.$app-font-label-medium;    }
.app-label-small     { font: var.$app-font-label-small;     }

h1 { @extend .app-display-large;  margin: 32px 0; }
h2 { @extend .app-display-medium; margin: 32px 0; }
h3 { @extend .app-display-small;  margin: 16px 0; }
h1, h2, h3 {
  & + p { @extend .app-body-large; }
}