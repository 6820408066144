.no-max-width {
  max-width: none;
}

.no-max-height {
  max-height: none;
}

.no-min-height {
  min-height: 0;
}

.no-min-width {
  min-width: 0;
}

.min-width-min-content {
  min-width: min-content;
}

.no-height {
  height: 0;
}

.no-width {
  width: 0;
}

.inherit-height {
  height: inherit;
}

.inherit-width {
  width: inherit;
}

.border-box {
  box-sizing: border-box;
}

.h-full, .full-height {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-svh {
  height: 100svh;
}

.h-lvh {
  height: 100lvh;
}

.h-dvh {
  height: 100dvh;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.h-fit {
  height: fit-content;
}

.h-auto {
  height: auto;
}

.w-full, .full-width {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-svw {
  width: 100svw;
}

.w-lvw {
  width: 100lvw;
}

.w-dvw {
  width: 100dvw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.w-auto {
  width: auto;
}

@for $i from 0 through 120 {
  .min-width-#{$i*2} {
    min-width: #{$i*2}px;
  }
  .min-height-#{$i*2} {
    min-height: #{$i*2}px;
  }
  .max-width-#{$i*2} {
    max-width: #{$i*2}px;
  }
  .max-height-#{$i*2} {
    max-height: #{$i*2}px;
  }
}

@for $i from 0 through 40 {
  .w-#{$i} {
    width: #{$i}em;
  }
}