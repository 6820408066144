
@for $i from 0 through 5 {
  .z-#{$i*10} {
    z-index: #{$i*10};
  }
  .-z-#{$i*10} {
    z-index: -#{$i*10};
  }
}

.z-auto {
  z-index: auto;
}

.z-inf {
  z-index: 9999999;
}