@use '../variables/defaults.scss' as Defaults;
@use '../variables/colors.scss' as Colors;

.border-radius {
  border-radius: Defaults.$border-radius;
}

.border-radius-inherit {
  border-radius: inherit;
}

.border-radius-container {
  border-radius: Defaults.$border-radius-container;
}

.border-radius-top-left {
  border-top-left-radius: Defaults.$border-radius;
}

.border-radius-top-right {
  border-top-right-radius: Defaults.$border-radius;
}

.border-radius-bottom-left {
  border-bottom-left-radius: Defaults.$border-radius;
}

.border-radius-bottom-right {
  border-bottom-right-radius: Defaults.$border-radius;
}

@for $i from 0 through 12 {
  .border-radius-#{$i*2} {
    border-radius: $i*2px;
  }
}

.border-radius-circle {
  border-radius: 50%;
}

.border,
.border-primary {
  border: 1px solid Colors.$primary;
}

.border-accent {
  border: 1px solid Colors.$accent;
}

.border-none {
  border: none;
}