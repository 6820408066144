@use 'abstract/variables' as var;

.primary {
  color: var.$primary !important;
}

.primary-bg {
  background: var.$primary !important;
}

.accent {
  color: var.$accent !important;
}

.accent-bg {
  background: var.$accent !important;
}

.white {
  color: white !important;
}

.grey {
  color: var.$grey !important;
}

.grey-bg {
  background: var.$grey !important;
}

.white-bg {
  background: white !important;
}

.warn {
  color: var.$warn-light !important;
}

.warn-bg {
  background: var.$warn-light !important;
}

.ok-green {
  color: var.$ok-green !important;
}

.error-red {
  color: var.$error-red !important;
}

.warning-orange {
  color: var.$warning-orange !important;
}

.remark-blue {
  color: var.$remark-blue !important;
}

.warn-color {
  color: var(--warn-color) !important
}

.bg-surface {
  background: var.$app-color-surface;
}

.on-surface {
  color: var.$app-color-on-surface;
}

.bg-surface-bright {
  background: var.$app-color-surface-bright;
}

.bg-surface-container-lowest {
  background: var.$app-color-surface-container-lowest;
}

.bg-surface-container-low {
  background: var.$app-color-surface-container-low;
}

.bg-surface-container {
  background: var.$app-color-surface-container;
}

.bg-surface-container-high {
  background: var.$app-color-surface-container-high;
}

.bg-surface-container-highest {
  background: var.$app-color-surface-container-highest;
}

.bg-surface-dim {
  background: var.$app-color-surface-dim;
}

.primary-container {
  color: var.$app-color-on-primary-container;
  background: var.$app-color-primary-container;
}

.bg-primary-container {
  background: var.$app-color-primary-container;
}


.secondary-bg {
  background: var.$app-color-secondary !important;
}

.secondary-container {
  color: var.$app-color-on-secondary-container !important;
  background: var.$app-color-secondary-container !important;
}

.tertiary-bg {
  background: var.$app-color-tertiary !important;
}

.tertiary-container {
  color: var.$app-color-on-tertiary-container !important;
  background: var.$app-color-tertiary-container !important;
}

.app-error-container {
  color: var.$app-color-on-error-container !important;
  background: var.$app-color-error-container !important;
}

.app-success-container {
  background: color-mix(in srgb, #{var.$success-green}, white 80%) !important;
}