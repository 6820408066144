@use '@angular/material' as mat;
@use 'abstract/variables' as var;

table {
  @include mat.table-overrides((
    header-headline-color: var.$app-color-on-surface-variant,

    header-headline-font:	       var(--mat-sys-label-large-font),
    header-headline-line-height: var(--mat-sys-label-large-line-height),
    header-headline-size:	       var(--mat-sys-label-large-size),
    header-headline-weight:	     var(--mat-sys-label-large-weight),
    header-headline-tracking:	   var(--mat-sys-label-large-tracking),

    // border color
    row-item-outline-color: var.$app-color-neutral-95,
  ));

  // other overrides that are not available in the mat.table-overrides mixin
  .mat-mdc-header-cell {
    background-color: var.$app-color-surface-container !important;
    border-color: var.$app-color-surface-container !important;

    &.mat-sort-header {
      transition: color var.$transition-duration ease-in-out;
      will-change: color;
      &:hover {
        color: var.$app-color-on-surface;
      }
    }
  }

  // table row hover effect
  .mat-mdc-cell {
    transition: filter 0.1s;
    will-change: filter;   // <- this is important for performance. it tells the browser that the filter property will change and it should prepare for it. otherwise the browser repaints a large part of the screen when hovering over a row
  }
  tr:hover .mat-mdc-cell {
    // bright theme
    filter: brightness(0.95);
    // // dark theme (NOT YET SUPPORTED)
    // filter: brightness(1.2);
  }

  // row deleting effect
  tr.table-deleting .mat-mdc-cell {
    background-color: var.$app-color-error;
    color: var.$app-color-on-error;
  }

}

