@use '@angular/material/core/style/variables' as v;

////
//// break point classes
////
@mixin hide-below($breakpoint) {
  @media (max-width: $breakpoint) {
    display: none !important;
  }
}

@mixin hide-above($breakpoint) {
  // plus 1px to avoid a width were hide-below and hide-above are both active
  @media (min-width: calc($breakpoint + 1px)) {
    display: none !important;
  }
}

@mixin height-100vh-minus-toolbar ($additional-offset: 0px) {
  height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { height: calc(100vh - 56px - $additional-offset); }
}

@mixin min-height-100vh-minus-toolbar ($additional-offset: 0px) {
  min-height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { min-height: calc(100vh - 56px - $additional-offset); }
}

@mixin max-height-100vh-minus-toolbar ($additional-offset: 0px) {
  max-height: calc(100vh - 64px - $additional-offset);
  @media (#{v.$xsmall}) { max-height: calc(100vh - 56px - $additional-offset); }
}
