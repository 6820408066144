////
//// DO NOT INCLUDE OR USE THIS FILE!
//// (it is loaded into the app via the angular.json)
////
@use 'sass:map';

@use '@angular/material' as mat;

@use './themes/schoolsoft' as SCS;
@use './themes/royal'      as RS;
@use './themes/sdui'       as SDUI;
@use './themes/additio'    as Additio;
@use './themes/pupil'      as Pupil;
@use './themes/konecto'    as Konecto;

// component overrides
@import './components';

// import the variable fonts
@import "@fontsource-variable/roboto-flex/full";   // all axes
@import "@fontsource-variable/open-sans/wght";     // only weight

html {
  color-scheme: light;
  @include mat.theme((
    color: (
      primary: RS.$primary-palette,
      tertiary: RS.$tertiary-palette,
      theme-type: light,
    ),
    typography: (
      plain-family: Roboto Flex Variable,
      brand-family: Open Sans Variable,
      // bold-weight: 900,
      // medium-weight: 500,
      // regular-weight: 300,
    ),
    density: -1
  ));
}

body {

  // activate theme depending on the class of the body
  @mixin apply-theme($primary-palette, $tertiary-palette, $primary-color, $secondary-color) {
    @include mat.theme((
      color: (
        primary: $primary-palette,
        tertiary: $tertiary-palette,
        theme-type: light,
      )
    ));

    & {
      // other theme colors that needs to be exposed manually
      --primary-color:   #{$primary-color};
      --secondary-color: #{$secondary-color};

      $neutral-palette: map.get($primary-palette, neutral);
      $neutral-variant-palette: map.get($primary-palette, neutral-variant);
      $error-palette: map.get($primary-palette, error);

      // how to handle light/dark mode?
      --app-color-primary-90: #{map.get($primary-palette, 90)};
      --app-color-neutral-90: #{map.get($neutral-palette, 90)};
      --app-color-neutral-95: #{map.get($neutral-palette, 95)};
      --app-color-neutral-variant-90: #{map.get($neutral-variant-palette, 90)};
      --app-color-error-fixed-dim: #{map.get($error-palette, 80)};
    }
  }

  &.default-theme { @include apply-theme(RS.$primary-palette,      RS.$tertiary-palette,      RS.$primary-color,      RS.$secondary-color     ); }
  &.scs-theme     { @include apply-theme(SCS.$primary-palette,     SCS.$tertiary-palette,     SCS.$primary-color,     SCS.$secondary-color    ); }
  &.sdui-theme    { @include apply-theme(SDUI.$primary-palette,    SDUI.$tertiary-palette,    SDUI.$primary-color,    SDUI.$secondary-color   ); }
  &.additio-theme { @include apply-theme(Additio.$primary-palette, Additio.$tertiary-palette, Additio.$primary-color, Additio.$secondary-color); }
  &.pupil-theme   { @include apply-theme(Pupil.$primary-palette,   Pupil.$tertiary-palette,   Pupil.$primary-color,   Pupil.$secondary-color  ); }
  &.konecto-theme { @include apply-theme(Konecto.$primary-palette, Konecto.$tertiary-palette, Konecto.$primary-color, Konecto.$secondary-color); }
}


////
//// more dense components
////
.dense-0 {
  @include mat.all-component-densities(-0);
}
.dense-1 {
  @include mat.all-component-densities(-1);
}
.dense-2 {
  @include mat.all-component-densities(-2);
}
.dense-3 {
  @include mat.all-component-densities(-3);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 10px;
    }
  }
}
.dense-4 {
  // a value smaller then -3 is not supported for the "all-component-densities" mixin hence we need to set the densities manually
  // (https://github.com/angular/components/issues/26613#issuecomment-1439688797)
  @include mat.form-field-density(-4);
  @include mat.icon-density(-4);
  @include mat.icon-button-density(-4);
  @include mat.slider-density(-4);
  @include mat.button-toggle-density(-4);
  @include mat.chips-density(-4);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 8px;
    }
  }
}
.dense-5 {
  @include mat.form-field-density(-5);
  @include mat.icon-density(-5);
  @include mat.icon-button-density(-5);
  @include mat.slider-density(-5);
  @include mat.button-toggle-density(-5);
  @include mat.chips-density(-5);

  // need to set the padding of icons manually
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &>.mat-icon {
      padding: 6px;
    }
  }
}

////
//// elevations
////
@for $i from 0 through 5 {
  .mat-elevation-z#{$i} {
    box-shadow: var(--mat-sys-level#{$i});
  }
}


// filled material symbols class
mat-icon.filled {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24;
}

