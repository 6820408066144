@use '@angular/material' as mat;
@use 'abstract/variables' as var;

.mat-toolbar {
  $toolbar-border-color: var.$app-color-neutral-variant-90; // same as for "mat-tab-nav-bar"

  &.app-toolbar-primary {
    @include mat.toolbar-overrides((
      container-background-color: var.$app-color-primary-fixed,
      container-text-color: var.$app-color-on-primary-fixed
    ));

    // if there is a border top/bottom
    border-top-color: $toolbar-border-color;
    border-bottom-color: $toolbar-border-color;
  }

  &.app-toolbar-secondary {
    @include mat.toolbar-overrides((
      container-background-color: var.$app-color-secondary-fixed,
      container-text-color: var.$app-color-on-secondary-fixed
    ));

    // if there is a border top/bottom
    border-top-color: $toolbar-border-color;
    border-bottom-color: $toolbar-border-color;
  }

  &.app-toolbar-surface {
    @include mat.toolbar-overrides((
      container-background-color: var.$app-color-surface-container,
      container-text-color: var.$app-color-on-surface-variant
    ));

    // if there is a border top/bottom
    border-bottom-color: $toolbar-border-color;
    border-top-color: $toolbar-border-color;
  }

  &.app-toolbar-surface-lowest {
    @include mat.toolbar-overrides((
      container-background-color: var.$app-color-surface-container-lowest,
      container-text-color: var.$app-color-on-surface-variant
    ));

    // if there is a border top/bottom
    border-bottom-color: $toolbar-border-color;
    border-top-color: $toolbar-border-color;
  }

  &.app-toolbar-top-border {
    border-top: 1px solid $toolbar-border-color;
  }

  &.app-toolbar-bottom-border {
    border-bottom: 1px solid $toolbar-border-color;
  }

  &.app-toolbar-border-color {
    border-color: $toolbar-border-color;
  }
}
