@use 'abstract/variables' as var;

@mixin flex {

  .flex {
    display: flex !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-nowrap-reverse {
    flex-wrap: nowrap-reverse !important;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .flex-align-self-center {
    align-self: center !important;
  }

  .flex-align-self-start {
    align-self: flex-start !important;
  }

  .flex-align-self-end {
    align-self: flex-end !important;
  }

  .flex-align-self-baseline {
    align-self: baseline !important;
  }

  .flex-align-self-stretch {
    align-self: stretch !important;
  }

  .flex-align-center {
    display: flex !important;
    align-items: center !important;
  }

  .flex-align-start {
    align-items: flex-start !important;
  }

  .flex-align-end {
    align-items: flex-end !important;
  }

  .flex-align-baseline {
    align-items: baseline !important;
  }

  .flex-align-stretch {
    align-items: stretch !important;
  }

  .flex-justify-center {
    display: flex;
    justify-content: center !important;
  }

  .flex-justify-start {
    justify-content: flex-start !important;
  }

  .flex-justify-end {
    justify-content: flex-end !important;
  }

  .flex-justify-space-between {
    justify-content: space-between !important;
  }

  .flex-justify-space-around {
    justify-content: space-around !important;
  }

  .flex-justify-space-evenly {
    justify-content: space-evenly !important;
  }

  .flex-gap {
    gap: var.$default-grid-gap;
  }

  .flex-gap-0 {
    gap: 0;
  }

  @for $i from 0 through 120 {
    .flex-gap-#{$i} {
      gap: #{$i}px;
    }
  }
}
