
.mat-form-field-appearance-fill {
  &.transparent {
    // transparent background as long as it's not focused
    .mdc-text-field--filled:not(.mdc-text-field--focused) {
      background-color: transparent;
    }

    // no borders at any point
    .mdc-text-field--filled .mdc-line-ripple::before {
      border-bottom-color: transparent;
    }
  }

  &.rounded {
    .mdc-text-field--filled {
      border-bottom-right-radius: var(--mat-sys-corner-extra-small) !important;
      border-bottom-left-radius: var(--mat-sys-corner-extra-small) !important;
    }
  }

  &.without-borders {
    .mdc-text-field--filled .mdc-line-ripple {
      &::before, &::after {
        border-bottom-width: 0;
      }
    }
  }
}

////
//// To prevent the floating label to cut the top of tall letters, such as "Å"
////
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  line-height: 21px !important;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(var(--mat-form-field-container-height) / 2 - 1px) !important;
}