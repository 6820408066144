@use '../variables/defaults.scss' as Defaults;
@use '../variables/colors.scss' as Colors;

.outline,
.outline-primary {
  outline: 1px solid Colors.$primary;
}

.outline-accent {
  outline: 1px solid Colors.$accent;
}

.outline-dark {
  outline: 1px solid Colors.$dark-grey;
}