@use 'abstract/mixins' as mix;
@use 'abstract/variables' as var;

$material-symbols-font-path: 'material-symbols/';
@import 'material-symbols';

@import 'app/core/push-notification/style.scss';

@import './animations';
@import './typography.scss';
@import './text.scss';
@import './border.scss';
@import './color.scss';
@import './dialog.scss';
@import './display.scss';
@import './dragula.scss';
@import './outline.scss';
@import './overflow.scss';
@import './position.scss';
@import './size.scss';
@import './breakpoints.scss';
@import './view.scss';
@import './article.scss';
@import './z-index.scss';

@import './components';
@import './directives';

html, body {
  background-color: var.$app-color-surface;
  color: var.$app-color-on-surface;
  margin: 0px;

  /**
  * Utility classes
  */
  @include mix.flex;
  @include mix.margin;
  @include mix.padding;
}

body {
  // do not apply to html as it will affect the "rem" which is used by material for setting font sizes
  font: var.$app-font-body-medium;
}

::-moz-selection {
  background: var.$primary;
  color: white;
}

::selection {
  background: var.$primary;
  color: white;
}

// th {
//   color: var.$font-secondary;
//   white-space: nowrap;
//   text-align: left;
//   font-weight: 500;
//   text-transform: uppercase;
//   letter-spacing: 0.04em;
//   font-size: 12px;
// }

[hidden] {
  display: none !important;
}

.pointer-events-none,
.non-clickable {
  pointer-events: none;
}

.clickable {
  cursor: pointer !important;
}

.draggable {
  cursor: move !important;
}

.help {
  cursor: help !important;
}

.right {
  float: right;
}

.spacer {
  flex: 1 1 auto;
}

.shadow-none {
  box-shadow: none !important;
}

// exist in "./text.scss"
// .uppercase {
//   text-transform: uppercase;
// }

// an icon within a button should listen to the button's disabled state
// /* OLD */ .royal-icon:not(:disabled) {
// /* OLD */   color: var.$icon-color;
// /* OLD */ }
mat-icon.royal-icon {
  color: var.$icon-color;
}
button:disabled mat-icon.royal-icon, .disabled mat-icon.royal-icon, mat-icon.royal-icon.disabled {
  color: rgba(0, 0, 0, 0.26);   // this is the disabled color of the icon (preferably accessed as a variable...)
}

.table {
  width: 100%;
}

.no-padding-card {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.info-snackbar {
  color: black;
  background: whitesmoke;
}

.green-snackbar {
  background: #00ff00;
  color: black;
}

.warn-snackbar {
  background: var.$royal-warn;
}

.mat-mdc-menu-panel {
  max-width: 100vw !important;
}

// use safaris own scrollbar
body:not(.is-safari) .dark-scrollbar  {
  @include mix.dark-scrollbar;
}
body.is-safari .dark-scrollbar  {
  overflow: auto !important;
}

// use safaris own scrollbar
body:not(.is-safari) .dark-vertical-scrollbar  {
  overflow-x: hidden !important;
  @include mix.dark-scrollbar;

  // make no scrollbar is visible, make room for the scrollbar gutter by add ing padding
  &.stable {
    scrollbar-gutter: stable;
  }
}
body.is-safari .dark-vertical-scrollbar  {
  overflow: auto !important;
  overflow-x: hidden !important;
}




// .table-deleting-text {
//   font-size: 16px;
//   font-weight: 500;
//   text-transform: uppercase;
//   letter-spacing: 0.07em;
//   border-radius: 10px;
//   color: white;
//   position: absolute;
//   z-index: 100;
//   width: stretch;
//   height: 52px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.list-item-anim {
  display: block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}

.list-item-anim:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 100%;
  top: 0;
  background: var.$primary;
  width: 4px;
  transition-property: bottom;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.list-item-anim:hover:before, .list-item-anim:focus:before, .list-item-anim:active:before {
  bottom: 0;
  right: 0px;
}

// .mat-mdc-header-cell {
//   // 600 does not look good in safari
//   font-weight: 500 !important;
// }


.new-line-tooltip {
  white-space: pre-line;
}
.left-align-tooltip {
  white-space: pre-line;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

button.icon-with-text mat-icon {
    margin-left: -4px;
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top;
}

.pointer-events-auto {
  pointer-events: auto;
}

mat-form-field.no-padding-bottom .mat-form-field-wrapper {
  padding-bottom: 0;
}

// if inside disabled button
button:disabled {
  .primary, .ok-green, .error-red, .warning-orange {
    color: rgba(0, 0, 0, 0.26) !important;

    .mat-badge-content {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-options-sticky {
  position: sticky !important;
  z-index: 100;
  top: 0;
  background-color: white !important;
}

////
//// chips
////
span.chip-outer {
  display: inline-block;
  padding-left:  2px;
  padding-right: 2px;

  span.chip {
    background:    var.$primary;
    border-radius: 4px;
    padding-left:  2px;
    padding-right: 2px;

    color: white;
  }
}
.mat-mdc-menu-panel.no-vertical-padding .mat-mdc-menu-content {
  padding-top:    0 !important;
  padding-bottom: 0 !important;
}

.main-toolbar {
  height: 64px !important; // otherwise it becomes 56px on small screens
}

////
////
////
.cdk-overlay-pane {
  &.full-width-dialog {
    max-width: 98vw !important;
  }
  &.position-relative .mat-mdc-dialog-container {
    position: relative !important;
  }
  &.no-padding .mat-mdc-dialog-container {
    padding: 0 !important;
  }
  &.overflow-hidden .mat-mdc-dialog-container {
    overflow: hidden !important;
  }
  &.stretch-vertically .mat-mdc-dialog-container {
    display: flex;
    align-items: stretch;
    overflow-x: hidden;

    // only the one related to the dialog
    > .ng-star-inserted {
      flex: 1;
    }
  }
  // DEPRECATED: risky to use, the dialog might not be able to scroll
  // &.overflow-visible {
    // .mat-mdc-dialog-container, .mat-mdc-dialog-content {
      // overflow: visible !important;
    // }
  // }
}

/*
  Rotating css class
*/
.rotating, .rotating-clockwise {
  animation: rotating-anticlockwise 2s linear infinite;
  @keyframes rotating-anticlockwise {
    from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
  }
}
.rotating-anticlockwise {
  animation: rotating-clockwise 2s linear infinite;
  @keyframes rotating-clockwise {
    from { transform: rotate(0deg); }
    to   { transform: rotate(-360deg); }
  }
}

.rotate90 {
  transform: rotate(90deg);
}

/*
  Expandable card
*/
$expand-amimation-duration-stage-1: 0.5s;
$expand-amimation-duration-stage-2: 0.25s;

.expanded-card {
  // height: 366px;
  box-sizing: border-box;
  position: absolute;
  z-index: 100;
  overflow-y: hidden;

  .close {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 100;
  }

  &.animate {
    animation-name: expand-vert, expand-horiz;
    animation-fill-mode: both;
    animation-delay: 0s, $expand-amimation-duration-stage-1;
    animation-duration: $expand-amimation-duration-stage-1, $expand-amimation-duration-stage-2;
  }
}

@keyframes expand-vert {
  0% {
    height: 366px;
  }
  100% {
    height: 100vh;
    top: 0px;
  }
}

@keyframes expand-horiz {
  0% {
  }
  100% {
    left: 0px;
    width: 100vw;
    border-radius: 0;
  }
}