@use './../mixins' as mix;
@use './../variables' as var;

.hide-below-small    { @include mix.hide-below(var.$break-small); }
.hide-below-medium   { @include mix.hide-below(var.$break-medium); }
.hide-below-tablet   { @include mix.hide-below(var.$break-tablet); }
.hide-below-laptop   { @include mix.hide-below(var.$break-laptop); }
.hide-below-1280     { @include mix.hide-below(var.$break-1280); }
.hide-below-laptop-L { @include mix.hide-below(var.$break-laptop-L); }
.hide-below-4k       { @include mix.hide-below(var.$break-4k); }

.hide-above-small    { @include mix.hide-above(var.$break-small); }
.hide-above-medium   { @include mix.hide-above(var.$break-medium); }
.hide-above-tablet   { @include mix.hide-above(var.$break-tablet); }
.hide-above-laptop   { @include mix.hide-above(var.$break-laptop); }
.hide-above-1280     { @include mix.hide-above(var.$break-1280); }
.hide-above-laptop-L { @include mix.hide-above(var.$break-laptop-L); }
.hide-above-4k       { @include mix.hide-above(var.$break-4k); }
