@use '../variables/defaults.scss' as Defaults;

@mixin padding {
  .no-padding {
    padding: 0 !important;
  }

  .no-inline-padding {
    padding-inline: 0 !important;
  }

  .no-block-padding {
    padding-block: 0 !important;
  }

  .padding {
    padding: Defaults.$padding;
  }

  .padding-inline {
    padding-inline: Defaults.$padding;
  }

  .padding-block {
    padding-block: Defaults.$padding;
  }

  .padding-top {
    padding-top: Defaults.$padding;
  }

  .padding-bottom {
    padding-bottom: Defaults.$padding;
  }

  .padding-left {
    padding-left: Defaults.$padding;
  }

  .padding-right {
    padding-right: Defaults.$padding;
  }

  @for $i from 0 through 120 {
    .padding-#{$i*2} {
      padding: $i*2px;
    }
    .padding-inline-#{$i*2} {
      padding-inline: $i*2px;
    }
    .padding-block-#{$i*2} {
      padding-block: $i*2px;
    }
    .padding-top-#{$i*2} {
      padding-top: $i*2px;
    }
    .padding-bottom-#{$i*2} {
      padding-bottom: $i*2px;
    }
    .padding-left-#{$i*2} {
      padding-left: $i*2px;
    }
    .padding-right-#{$i*2} {
      padding-right: $i*2px;
    }
  }
}