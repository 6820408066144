
@use 'abstract/variables' as var;
@use 'abstract/mixins' as mix;

// /* REMOVE? */ .royal-table-container, .table-container {  // -> app-table-container
// /* REMOVE? */   position: relative;
// /* REMOVE? */   height: 100%;
// /* REMOVE? */   display: flex;
// /* REMOVE? */   flex-direction: column;
// /* REMOVE? */   overflow-y: auto;
// /* REMOVE? */
// /* REMOVE? */   .table-wrapper {
// /* REMOVE? */     flex-grow: 1;
// /* REMOVE? */   }
// /* REMOVE? */
// /* REMOVE? */ }


////
//// Usage of the following classes to make the table component span the whole height of the parent container
////
//
// <... class="app-table-component">   // <- typically the host element, append using @HostBinding(class.app-table-component) private _hostCLass = true;
//             ^^^^^^^^^^^^^^^^^^^
//   <mat-toolbar class="app-table-toolbar">...</mat-toolbar>
//                       ^^^^^^^^^^^^^^^^^
//   <div class="app-table-container">
//               ^^^^^^^^^^^^^^^^^^^
//     <app-loading></app-loading>   // <- and other things that should cover the table but not the toolbar
//
//     <table>...</table>
//   </div>
//
//   <mat-paginator></mat-paginator>
//
// </...>
//

.app-table-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  mat-toolbar.app-table-toolbar {
    background-color: var.$app-color-surface-container;
    color: var.$app-color-on-surface-variant;
    flex-shrink: 0;

    // add a higher z index to the toolbar than a following sibling table
    // so that button shadows are not cut off
    z-index: 2;
    & ~ .app-table-container {
      z-index: 1;
    }
  }

  .app-table-container {
    flex: 1;
    overflow: auto;

    // in order to contain position absolute elements
    position: relative;

    @include mix.dark-scrollbar;
  }
}


////
//// table helper classes
////

table {

  // shrinks the column width to the minimum width of the content
  th, td {
    &.shrink {
      width: 0;
    }
  }

  // makes the table header cells nowrap
  &.header-nowrap {
    th {
      white-space: nowrap;
    }
  }
}