@use 'abstract/variables' as var;

article {
  padding: var.$article-padding-top var.$article-padding-horizontal var.$article-padding-bottom;

  $two-columns-width: var.$break-tablet;
  $one-column-width:  var.$break-small;

  .block {
    display: grid;
    position: relative;
    max-width: var.$max-content;
    margin: 0 auto;
    grid-gap: 20px;

    // reduce the number of columns on smaller screens
    grid-template-columns: repeat(auto-fit, minmax(calc(25% - 20px), 1fr));
    @media (max-width: $two-columns-width) {
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
    }
    @media (max-width: $one-column-width) {
      grid-template-columns: 1fr;
    }

    overflow-x: visible;

    // TODO: perhaps make "snug" the default and have a class for "non snug"
    padding-bottom: 104px;
    &.snug {
      padding-bottom: 56px;
    }


    .chunk, .text-chunk {
      &.first-column {
        grid-column-start: 1;
      }

      @for $i from 1 through 4 {
        &.span-#{$i} {
          grid-column-end: span #{$i};
          @media (max-width: $two-columns-width) {
            grid-column-end: span #{min($i, 2)};
          }
          @media (max-width: $one-column-width) {
            grid-column-end: span 1;
          }
        }
      }

      &.hide-if-lt-4-columns {
        @media (max-width: $two-columns-width) {
          display: none !important;
        }
      }
      &.hide-if-lt-2-columns {
        @media (max-width: $one-column-width) {
          display: none !important;
        }
      }
    }

    .chunk {
      // background-color: gray;

      &.no-vertical-space {
        height: 0;
        display: flex;
        overflow: visible;

        app-svg-image {
          height: max-content;
        }
      }
    }

    .text-chunk {
      padding-right: 1px;
      padding-left: 1px;

      & > :first-child {
        margin-top: 0;
      }

      p, ul, ol {
        max-width: 760px;
      }
    }
  }
}