.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-relative {
  position: relative !important;
}

.position-right {
  right: 0;
}

.position-left {
  left: 0;
}

.position-top {
  top: 0;
}

.position-bottom {
  bottom: 0;
}

.position-top-left {
  top: 0;
  left: 0;
}

.position-top-right {
  top: 0;
  right: 0;
}

.position-bottom-left {
  bottom: 0;
  left: 0;
}

.position-bottom-right {
  bottom: 0;
  right: 0;
}

.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}