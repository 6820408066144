
.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.no-wrap,
.inline-text {
  white-space: nowrap;
}

.royal-text-color {
  color: var.$font-color;
}

.royal-text-primary {
  color: var.$font-primary !important;
}

.royal-text-secondary {
  color: var.$font-secondary !important;
}

.superscript {
  vertical-align: super;
  font-size: 9px;
  font-weight: 600;
}

.royal-font-highlight {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  border-radius: 10px;

  &:first-child {
    border-top: none;
  }
}

.royal-text-warn {
  color: var.$warn;
}

.center-text {
  text-align: center;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.strike-through {
  text-decoration: line-through;
}