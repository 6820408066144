@use '@angular/material' as mat;
@use 'sass:map';
@use '@angular/material/core/m2/palette' as Palette;

// exposes css colors variables as sass variables
$app-color-primary:                   var(--mat-sys-primary);
$app-color-on-primary:                var(--mat-sys-on-primary);
$app-color-primary-container:         var(--mat-sys-primary-container);
$app-color-on-primary-container:      var(--mat-sys-on-primary-container);
$app-color-primary-fixed:             var(--mat-sys-primary-fixed);
$app-color-primary-fixed-dim:         var(--mat-sys-primary-fixed-dim);
$app-color-on-primary-fixed:          var(--mat-sys-on-primary-fixed);
$app-color-on-primary-fixed-variant:  var(--mat-sys-on-primary-fixed-variant);
$app-color-secondary:                 var(--mat-sys-secondary);
$app-color-on-secondary:              var(--mat-sys-on-secondary);
$app-color-secondary-container:       var(--mat-sys-secondary-container);
$app-color-on-secondary-container:    var(--mat-sys-on-secondary-container);
$app-color-secondary-fixed:           var(--mat-sys-secondary-fixed);
$app-color-secondary-fixed-dim:       var(--mat-sys-secondary-fixed-dim);
$app-color-on-secondary-fixed:        var(--mat-sys-on-secondary-fixed);
$app-color-on-secondary-fixed-:       var(--mat-sys-on-secondary-fixed-);
$app-color-tertiary:                  var(--mat-sys-tertiary);
$app-color-on-tertiary:               var(--mat-sys-on-tertiary);
$app-color-tertiary-container:        var(--mat-sys-tertiary-container);
$app-color-on-tertiary-container:     var(--mat-sys-on-tertiary-container);
$app-color-tertiary-fixed:            var(--mat-sys-tertiary-fixed);
$app-color-tertiary-fixed-dim:        var(--mat-sys-tertiary-fixed-dim);
$app-color-on-tertiary-fixed:         var(--mat-sys-on-tertiary-fixed);
$app-color-on-tertiary-fixed-variant: var(--mat-sys-on-tertiary-fixed-variant);
$app-color-error:                     var(--mat-sys-error);
$app-color-on-error:                  var(--mat-sys-on-error);
$app-color-error-container:           var(--mat-sys-error-container);
$app-color-on-error-container:        var(--mat-sys-on-error-container);
$app-color-surface-dim:               var(--mat-sys-surface-dim);
$app-color-surface:                   var(--mat-sys-surface);
$app-color-surface-bright:            var(--mat-sys-surface-bright);
$app-color-surface-container-lowest:  var(--mat-sys-surface-container-lowest);
$app-color-surface-container-low:     var(--mat-sys-surface-container-low);
$app-color-surface-container:         var(--mat-sys-surface-container);
$app-color-surface-container-high:    var(--mat-sys-surface-container-high);
$app-color-surface-container-highest: var(--mat-sys-surface-container-highest);
$app-color-on-surface:                var(--mat-sys-on-surface);
$app-color-on-surface-variant:        var(--mat-sys-on-surface-variant);
$app-color-outline:                   var(--mat-sys-outline);
$app-color-outline-variant:           var(--mat-sys-outline-variant);
$app-color-inverse-surface:           var(--mat-sys-inverse-surface);
$app-color-inverse-on-surface:        var(--mat-sys-inverse-on-surface);
$app-color-inverse-primary:           var(--mat-sys-inverse-primary);
$app-color-scrim:                     var(--mat-sys-scrim);
$app-color-shadow:                    var(--mat-sys-shadow);

// other theme colors that have been exposed manually
$app-color-primary-90: var(--app-color-primary-90);
$app-color-neutral-90: var(--app-color-neutral-90);
$app-color-neutral-95: var(--app-color-neutral-95);
$app-color-neutral-variant-90: var(--app-color-neutral-variant-90);
$app-color-error-fixed-dim: var(--app-color-error-fixed-dim);

////
//// royal-colors
////
$royal-icon-color: rgba(0, 0, 0, 0.54);
$royal-primary: var(--primary-color);
$royal-primary-light: #dcd0ff;
$royal-primary-lighter: #F0EAFE;
$royal-valid: #009c18;
$royal-warn: var(--warn-color);
$royal-warn-light: var(--warn-lighter-color);
$royal-accent: var(--secondary-color);
$royal-dark-white: #FDFDFD;
$royal-light-grey: whitesmoke;
$royal-grey: #ececec;
$royal-dark-grey: #b0b0b0;
$royal-dark: #2f2e41;
$royal-border-color: rgba(0, 0, 0, 0.12);
$royal-font-color: rgb(32, 33, 36);
$royal-font-primary: #0D0D0D;
$royal-font-secondary: #606060;

// expose schedule defect colors provided in "src/app/core/user-preferences/user-preferences.service.ts"
// (grab these from a typescript file somehow?)
$app-schedule-defect-color_overlap:                   var(--app-schedule-defect-color_overlap);
$app-schedule-defect-color_doubleBooking:             var(--app-schedule-defect-color_doubleBooking);
$app-schedule-defect-color_insufficientBookingMargin: var(--app-schedule-defect-color_insufficientBookingMargin);
$app-schedule-defect-color_insufficientBreakLength:   var(--app-schedule-defect-color_insufficientBreakLength);
$app-schedule-defect-color_overtime:                  var(--app-schedule-defect-color_overtime);
$app-schedule-defect-color_dailyOvertime:             var(--app-schedule-defect-color_dailyOvertime);
$app-schedule-defect-color_courseClustering:          var(--app-schedule-defect-color_courseClustering);
$app-schedule-defect-color_unavailableDay:            var(--app-schedule-defect-color_unavailableDay);
$app-schedule-defect-color_overflow:                  var(--app-schedule-defect-color_overflow);
$app-schedule-defect-color_missingDependency:         var(--app-schedule-defect-color_missingDependency);

////
//// other colors
////
$icon-color:      rgba(0, 0, 0, 0.54);

$primary:         var(--primary-color);
$primary-light:   #dcd0ff;
$primary-lighter: #F0EAFE;

$warn:            #e74c3c;
$warn-light:      #EF9A9A;

$valid:           #009c18;
$valid-light:     #00E022;

$accent:          var(--accent-color);

$grey:            #ececec;//var(--google-gray-700)
$dark-grey:       #b0b0b0;
$dark:            #2f2e41;

$gradient:         var(--gradient-color);

$font-color:      rgb(32, 33, 36); // #616161;
$font-primary:    #0D0D0D;
$font-secondary:  #606060;

$background-hover: #f5f5f5;



$ok-green:       #{map.get(mat.$m2-green-palette,  500)};
$success-green:  $ok-green;
$error-red:      #{map.get(mat.$m2-red-palette,    500)};
$warning-orange: #{map.get(mat.$m2-orange-palette, 500)};
$remark-blue:    #{map.get(mat.$m2-blue-palette,   500)};
$note-blue:      #E7F2FA;

/** @deprecate the first one */
$disabled-text: Palette.$dark-disabled-text;
$app-disabled-text-color: Palette.$dark-disabled-text;