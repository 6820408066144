.mat-mdc-paginator {
  container-type: inline-size;
  container-name: paginator;

  .mat-mdc-paginator-container {
    flex-wrap: nowrap;
  }

  @container paginator (width < 500px) {
    // Hide "Items per page: " label
    .mat-mdc-paginator-page-size-label {
      display: none;
    }

    .mat-mdc-paginator-range-actions {
      display: contents;
    }

    // Make the range label wrap and smaller
    .mat-mdc-paginator-range-label {
      white-space: nowrap;
      margin: 0;
      flex: 1;
      justify-content: center;
      display: flex;
    }
  }
}